import React, { Component } from "react"
import PropTypes from "prop-types"
import HTMLEditor from '../HTMLEditor'

class Section extends Component {
  onTitleChanged = (event) => {
    //this.props.onValueChanged('title')(event.target.value)
    // Possibility to change sections titles is disabled
  }

  render () {
    const { title, body, onValueChanged } = this.props
    return (
      <div className="row">
        <div className="col form-group">
          <input readOnly className="form-control" value={title} onChange={this.onTitleChanged}/>
          {/*
          <textarea className="form-control" value={this.props.body} onChange={this.props.onValueChanged('body')}/>
          */}
          <HTMLEditor
            content={body}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onContentStateChange={onValueChanged('body')}
          />
        </div>
      </div>
    );
  }
}

Section.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string
};

export default Section
