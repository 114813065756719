import React from "react"
import PropTypes from "prop-types"
import { EditorState, ContentState, convertFromHTML, convertToRaw, CompositeDecorator} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class HTMLEditor extends React.Component {
  constructor(props) {
    super(props)

    const blocks = convertFromHTML(props.content || '')
    var contentState = null

    if (blocks.contentBlocks) {
      contentState = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)
    }

    this.state = {
      editorState: contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty()
    }
    // this.onChange = (editorState) => this.setState({editorState});
  }

  onEditorStateChange = (editorState) => {
    const content = this.state.editorState.getCurrentContent();
    const rawContent = convertToRaw(content)
    this.setState({ editorState })
    console.log('content', content, rawContent, draftToHtml(rawContent))
    this.props.onContentStateChange(draftToHtml(rawContent))
  }

  render() {
    return <Editor
      editorState={this.state.editorState}
      // onChange={this.onChange}
      onEditorStateChange={this.onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'textAlign', 'link', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline']
        },
        list: {
          options: ['unordered', 'indent', 'outdent']
        }
      }}
    />
  }
}

HTMLEditor.propTypes = {
  content: PropTypes.string
};

export default HTMLEditor
