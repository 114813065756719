import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const Clear = ({ innerRef, innerProps }) => (
  <span aria-label="Close emoji" ref={innerRef} {...innerProps}>
    <p>Clear</p>
  </span>
)

const Icon = ({ innerRef, innerProps }) => (
  <span role="img" aria-label="Dropdown emoji" ref={innerRef} {...innerProps}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5L13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L11 5Z" fill="#2E3A4D"/>
      <path d="M19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L19 11Z" fill="#2E3A4D"/>
    </svg>
  </span>
)

const customComponents = {
  ClearIndicator: Clear,
  DropdownIndicator: Icon,
  IndicatorSeparator:() => null,
}

const CustomStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '16px',
    minHeight: '48px',
    border: state.isFocused ? '2px solid #00cbd5' : '1px solid #ADB6C4',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? '2px solid #00cbd5' : '1px solid #6F7A8C'
    }
  }),
  menu: provided => ({ ...provided, zIndex: 9999, fontSize: '16px', marginTop: 0 }),
  indicatorsContainer: provided => ({
    ...provided,
    alignItems: 'start',
    paddingTop: '11px',
    paddingRight: '11px',
    '& p': {
      marginRight: '8px',
      color: '#2E3A4D',
      fontSize: '12px',
      lineHeight: '18px',
      textDecoration: 'underline',
      paddingTop: '2px'
    }
  }),
}

class MultipleSelect extends React.Component {
  constructor(props) {
    super(props)

    this.animatedComponents = makeAnimated()
    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.options = props.options
    const chosenOptions = props.params && props.params.length ? props.params : null
    this.techStack = props.tech_stack
    this.state = {
      value: chosenOptions,
      selector: props.selector,
      inputValue: '',
      title: props.title
    }
  }

  onChange(value, { action, removedValue }) {
    let field = document.querySelector(`#${this.state.selector}`)
    let array = new Array

    if (value) {
      value.forEach(element => { array.push(element.value) })
    }
    field.value = array
    this.setState({ value: value })
  }

  onInputChange(query, { action }) {
    if (this.techStack && action !== 'input-change') { return }

    this.setState({ inputValue: query })
  }

  getPlaceholder() {
    const title = this.state.title

    if (title)
      return title;

    let source = this.state.selector

    return source[0].toUpperCase() + source.substring(1)
  }

  render() {
    return (
      <Select
        placeholder={this.getPlaceholder()}
        // components={this.animatedComponents}
        components={customComponents}
        value={this.state.value}
        inputValue={this.state.inputValue}
        isMulti
        onChange={this.onChange}
        options={this.options}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onInputChange={this.onInputChange}
        styles={CustomStyles}
      />
    )
  }
}

export default MultipleSelect
