import React, { Component } from "react"
import PropTypes from "prop-types"
import Section from "./Section"

class Form extends Component {
  constructor(props) {
    super(props);
    this.initState()
  }

  initState () {
    let sections = []
    try{
      // sections = JSON.parse(this.props.content) || []
      sections = JSON.parse(this.props.content) || [
        { title: 'Product', body: '' },
        { title: 'Business needs', body: '' },
        { title: 'Suggested solutions from Svitla', body: '' },
        { title: 'Outcomes for the client', body: '' },
        { title: 'Technologies', body: '' }
      ]
    } catch(e) {
      console.error(e)
    }
    this.state = { content: this.props.content, sections };
  }

  onValueChanged = (index) => {
    console.log('curry', index)
    return (name) => {
      return (value) => {
        console.log('value', value)
        // const target = event.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;

        let sections = this.state.sections
        sections[index][name] = value

        this.setState({ sections });
        document.getElementById('case_study_body').value = JSON.stringify(sections)
      }
    }
  }

  onAddSectionClick = (e) => {
    e.preventDefault()
    let sections = this.state.sections
    sections.push({ title: 'Technologies (second column)', body: '' })
    this.setState({ content: sections, sections })
  }

  onDelSectionClick = (e) => {
    e.preventDefault()
    let sections = this.state.sections
    sections.pop()
    this.setState({ content: sections, sections })
  }

  render () {
    let { content, sections } = this.state
    console.log(this.props, this.state)
    let button

    if (sections.length == 5) {
      button = <button onClick={this.onAddSectionClick} className="btn btn-secondary-1">Add Second Column</button>
    } else if (sections.length == 6) {
      button = <button onClick={this.onDelSectionClick} className="btn btn-secondary-1">Delete Second Column</button>
    }

    return (
      <div className="row">
        <div className="col">
          {sections && sections.map((section, index) => {
            return <Section title={section.title} body={section.body} key={index} onValueChanged={this.onValueChanged(index)} />
          }) }
          {button}
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  content: PropTypes.string
};

export default Form
