import React from "react"
import PropTypes from "prop-types"

class Show extends React.Component {
  constructor(props) {
    super(props);
    console.log(JSON.parse(this.props.content))
  }

  render () {
    const sections = JSON.parse(this.props.content)
    const listItems = sections.map((section, i) =>
      <div key={i}>
        <h3>{section.title}</h3>
        <div dangerouslySetInnerHTML={{__html: section.body}}></div>
      </div>
    );

    return (
      <React.Fragment>
        {listItems}
      </React.Fragment>
    );
  }
}

Show.propTypes = {
  content: PropTypes.string
};

export default Show
