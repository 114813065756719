import React from 'react'
import DayPicker from 'react-day-picker'
import './Calendar.css'
import { ModifiersUtils } from "react-day-picker";

export default class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.state = {
      selector: props.selector,
      selectedDay: undefined
    }
  }

  handleDayClick(day, modifiers) {
    if (modifiers.disabled) { return }
    this.setState({ selectedDay: day })

    let deadlineField = document.querySelector(`#${this.state.selector}`)
    deadlineField.value = `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`
  }

  render() {
    if (this.state.selectedDay) {
      console.log(this.state.selectedDay.getDate())
    }
    return (
      <div>
      {this.state.selectedDay ? (
        <p style={{paddingLeft: "20px"}}>{this.state
          .selectedDay
          .toLocaleDateString("en-US", { day: "numeric",
          month: "long",
          year: "numeric" })}</p>
        ) : (
          <p style={{color: "red", paddingLeft: "20px", fontSize: "16px"}}>Please select a deadline date.</p>
          )}
        <DayPicker
          showOutsideDays
          onDayClick={this.handleDayClick}
          fromMonth={new Date()}
          disabledDays={[
            {
              before: new Date()
            }
          ]}
          selectedDays={this.state.selectedDay}
        />
      </div>
    );
  }
}
