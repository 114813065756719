import consumer from "./consumer"

consumer.subscriptions.create("BasketChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const basket = document.getElementById("basket")
    const count = data.count
    const link = basket.querySelector("a")
    const itemsChanged = document.getElementById("items_changed")

    link.innerHTML = `Current Brochure<span class="fa-stack" data-count="${count}"></span>`
    if (itemsChanged) {
      itemsChanged.style.display = "block"
    }
  }
});
